import PropTypes from "prop-types";
import { Button } from "@mantine/core";
import { BouncyBtnStyle } from "../../../styles/sx-mixins";

const TableBtn = ({ children, sxProps, ...props }) => (
	<Button
		sx={{
			height: "auto",
			fontSize: "20px",
			lineHeight: "30px",
			padding: "10px 26px",
			borderRadius: "10px",
			"&:hover": BouncyBtnStyle,

			"@media (max-width: 990px)": { fontSize: "18px", lineHeight: "24px", padding: "10px 22px" },
			"@media (max-width: 486px)": { fontSize: "16px", padding: "7px 16px" },

			...sxProps,
		}}
		{...props}
	>
		{children}
	</Button>
);

TableBtn.propTypes = {
	children: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	sxProps: PropTypes.object,
};

export default TableBtn;
