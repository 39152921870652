import { PasswordInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import FormBtn from "./form-btn";
import { AuthInputStyles } from "../../../styles/sx-mixins";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {

	const queryParameters = new URLSearchParams(window.location.search)
	//console.log(queryParameters.get("type"));
	const authToken = queryParameters.get("token");
	//console.log(authToken);
	const navigate = useNavigate();

	const form = useForm({
		initialValues: { password: "", confirmPassword: "" },
		validate: {
			password: (value) => value.length < 8 && "Minimum 8 characters",
			confirmPassword: (value, values) => value !== values.password && "Passwords did not match",
		},
	});

	const handleSubmit = (values) => {
		// console.log(values);
		changePasswordHandler(values);
	};

	const changePasswordHandler = async ( formPassword ) => {
		console.log(formPassword);
		const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/reset-password/confirm/`, {
			method: "POST",
			referrer: "https://api.voterscore.com",
			headers: new Headers({ "Content-Type": "application/json" }),
			body: JSON.stringify({
				password: formPassword.password,
				token: authToken
			}),
		})
			.then((res) => {
				if (res?.status === 200) {
					//console.log("here");
					navigate("/auth");
				} else {
					form.setErrors({
						email: "Error finding your account. Try again...",
					});
				}
				return res.json();
			})
			.catch((err) => err.message);
		console.log(response);
		// Status code 201 meaning user account resource created...
	};

	return (
		<form onSubmit={form.onSubmit(handleSubmit)}>
			<PasswordInput
				styles={AuthInputStyles}
				mb="14px"
				placeholder="Set a New Password"
				visibilityToggleIcon={({ reveal }) => (reveal ? "Hide" : "Show")}
				{...form.getInputProps("password")}
			/>
			<PasswordInput
				styles={AuthInputStyles}
				mb="14px"
				placeholder="Repeat Password"
				visibilityToggleIcon={({ reveal }) => (reveal ? "Hide" : "Show")}
				{...form.getInputProps("confirmPassword")}
			/>

			<FormBtn text="Reset Password" spacing={["18px 20px", "14px 20px"]} />
		</form>
	);
};

export default ResetPassword;
