import PropTypes from "prop-types";
import { NoDataToDisplay, Loading } from "..";

//

const ErrorHandlerLayout = ({ children, isLoading, error }) => {
	error && console.log(error.message);

	return error ? <NoDataToDisplay /> : isLoading ? <Loading /> : children;
};

ErrorHandlerLayout.propTypes = {
	children: PropTypes.element.isRequired,
	isLoading: PropTypes.bool.isRequired,
	error: PropTypes.object,
};

export default ErrorHandlerLayout;
