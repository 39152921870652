import { Global } from "@mantine/core";

const MyGlobalStyles = () => (
	<Global
		styles={{
			"*, *::before, *::after": {
				fontSize: "inherit",
				color: "inherit",
				lineHeight: "inherit",
				boxSizing: "inherit",
			},

			"*::-webkit-scrollbar": { width: "10px", height: "10px" },
			"*::-webkit-scrollbar-thumb": { borderRadius: "10px", backgroundColor: `rgba(0, 0, 0, 0.25)` },
			"*::-webkit-scrollbar-track": { borderRadius: "10px", backgroundColor: `rgba(0, 0, 0, 0.15)` },

			body: { fontSize: "16px", lineHeight: "24px", boxSizing: "border-box" },
			img: { objectFit: "cover", maxWidth: "100%" },
			a: { textDecoration: "none" },
		}}
	/>
);

export default MyGlobalStyles;
