import { Button } from "@mantine/core";
import PropTypes from "prop-types";

// spacing array => desktop values at first index and mobile value at second...
const FormBtn = ({ text, spacing, ...props }) => (
	<Button
		{...props}
		type="submit"
		sx={{
			"&, :hover": { backgroundColor: "#5269D6" },
			height: "auto",
			fontSize: "16px",
			padding: spacing[0],
			"@media (max-width: 470px)": { padding: spacing[1] },
		}}
	>
		{text}
	</Button>
);

FormBtn.propTypes = {
	text: PropTypes.string.isRequired,
	spacing: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FormBtn;
