import { Box, Loader } from "@mantine/core";
import { FlexAllCenter } from "../../styles/sx-mixins";

//

const Loading = ({ size = "xl", variant = "bars", color }) => (
	<Box sx={{ ...FlexAllCenter, height: "70vh" }}>
		<Loader {...{ size, variant, color }} />
	</Box>
);

export default Loading;
