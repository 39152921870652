import { lazy, Suspense } from "react";
import { useAuthUser } from "react-auth-kit";
import { Route, Routes, useLocation } from "react-router-dom";
import { MainLayout, Loading, AuthPrompt } from "./components";

//

const Auth = lazy(() => import("./pages/auth"));
const Home = lazy(() => import("./pages/home"));
const Page404 = lazy(() => import("./pages/page404"));
const Landing = lazy(() => import("./pages/landing"));
const About = lazy(() => import("./pages/about"));
const Privacy = lazy(() => import("./pages/privacy"));
const Results = lazy(() => import("./pages/results"));
const Settings = lazy(() => import("./pages/settings"));
const Legislators = lazy(() => import("./pages/legislators"));
const BillDetails = lazy(() => import("./pages/bill-details"));
const BillSingle = lazy(() => import("./pages/bill-single"));
const LegislatorDetails = lazy(() => import("./pages/legislator-details"));
const FollowedLegislators = lazy(() => import("./pages/followed-legislators"));
const FindMy = lazy(() => import("./pages/find-my"));
const Help = lazy(() => import("./pages/help"));
//

const AppRoutes = () => {
	const session = useAuthUser();
	const { pathname } = useLocation();

	//console.log(session()?.user);

	const RoutesList = (
		<Suspense fallback={<Loading />}>
			<Routes>
				<Route path="/auth" element={<Auth />} />
				<Route path="/auth/:authType" element={<Auth />} />

				<Route path="*" element={<Page404 />} />

				<Route path="/" element={<Landing />} />
				<Route path="/about" element={<About />} />
				<Route path="/legal/privacy" element={<Privacy />} />
				<Route path="/home" element={<Home />} />
				<Route path="/help" element={<Help />} />
				<Route path="/results" element={<Results />} />
				<Route path="/findmy" element={<FindMy />} />
				<Route path="/legislators" element={<Legislators />} />
				<Route path="/bill-details/:endpoint" element={<BillDetails />} />
				<Route path="/bill/:state/:bill_title/:bill_year" element={<BillSingle />} />
				<Route path="/bills/:state/:bill_title/:bill_year" element={<BillSingle />} />
				<Route path="/bills/:state" element={<Home />} />
				<Route path="/bills" element={<Home />} />

				<Route path="/settings" element={session ? <Settings /> : <AuthPrompt />} />
				<Route path="/legislator-details/:endpoint" element={<LegislatorDetails />} />
				<Route path="/followed-legislators" element={session ? <FollowedLegislators /> : <AuthPrompt />} />
			</Routes>
		</Suspense>
	);

	return ["/", "/auth", "/about", "/legal/privacy"].includes(pathname) ? RoutesList : <MainLayout>{RoutesList}</MainLayout>;
};

export default AppRoutes;
