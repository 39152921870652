import PropTypes from "prop-types";
import { Select } from "@mantine/core";
import { HiChevronDown } from "react-icons/hi";

const FilterSelect = (props) => (
	<Select
		{...props}
		variant="unstyled"
		nothingFound="No options"
		maxDropdownHeight={300}
		rightSection={<HiChevronDown />}
		transition="pop-top-left"
		transitionDuration={140}
		sx={{
			input: {
				width: "200px",
				height: "auto",
				fontSize: "16px",
				backgroundColor: "white",
				padding: "14px 16px",
				borderRadius: "10px",
				lineHeight: "24px",
			},
		}}
	/>
);

FilterSelect.propTypes = {
	placeholder: PropTypes.string.isRequired,
	data: PropTypes.arrayOf(PropTypes.object),
};

export default FilterSelect;
