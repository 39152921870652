import { create } from "zustand";
import { devtools } from "zustand/middleware";

const searchStore = create(
	devtools(
		(set) => ({
			searchResult: {},
			searchBarLoading: false,
			setSearchResult: (results) => set({ searchResult: results }),
			setSearchBarLoading: (isLoading) => set({ searchBarLoading: isLoading }),
		}),
		{
			name: "search",
		}
	)
);

export default function useSearchStore() {
	return searchStore((state) => state);
}
