export const FlexAllCenter = {
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
};

export const FlexJusBet = {
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
};

export const AuthInputStyles = {
	input: {
		minHeight: "56px",
		fontSize: "14px",
		fontWeight: 500,
		backgroundColor: "#F5F6F8",
		padding: "10px 24px",
		"@media (max-width: 470px)": { minHeight: "48px", padding: "6px 14px" },
	},
	innerInput: {
		width: "100%",
		height: "auto",
		padding: "10px 24px",
		"@media (max-width: 470px)": { padding: "6px 14px" },
	},
	rightSection: {
		right: "20px",
		button: {
			fontSize: "14px",
			color: "rgba(102, 90, 225, 0.6)",
			"&:hover": { backgroundColor: "transparent" },
		},
	},
	error: { color: "#C92A2A" },
};

export const BouncyBtnStyle = { transform: "translateY(-1.4px) scale(1.001)" };
