import { useEffect, useState, useMemo } from "react";
import { Loading, TableLegislators } from "../components";
import { FlexAllCenter } from "../styles/sx-mixins";

import { AiFillPieChart } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { Group, Box, Table, Text, Pagination, Badge, Title, UnstyledButton, Input, Loader } from "@mantine/core";
import { SearchTableHeader, VoteBtn, TableBtn, PieModal } from "../components/tables/sub-components";
import { getApiData } from "../utils/fetch-api";
import { useMutation } from "@tanstack/react-query";
import { SearchIcon } from "../components/SvgAssets";


const useSortableData = (items, config = null) => {
	const [sortConfig, setSortConfig] = useState(config);
  
	const sortedItems = useMemo(() => {
	  let sortableItems = [...items];
	  if (sortConfig !== null) {
		sortableItems.sort((a, b) => {
		  if (a[sortConfig.key] < b[sortConfig.key]) {
			return sortConfig.direction === 'ascending' ? -1 : 1;
		  }
		  if (a[sortConfig.key] > b[sortConfig.key]) {
			return sortConfig.direction === 'ascending' ? 1 : -1;
		  }
		  return 0;
		});
	  }
	  return sortableItems;
	}, [items, sortConfig]);
  
	const requestSort = (key) => {
	  let direction = 'ascending';
	  if (
		sortConfig &&
		sortConfig.key === key &&
		sortConfig.direction === 'ascending'
	  ) {
		direction = 'descending';
	  }
	  setSortConfig({ key, direction });
	};
  
	return { items: sortedItems, requestSort, sortConfig };
  };

const FindMyLegislators = () => {
	const navigate = useNavigate();
	const [infoTab, setInfoTab] = useState(0);
	const { pathname } = useLocation();

	const queryParameters = new URLSearchParams(window.location.search)
	const searchParam = queryParameters.get("search")
	const [searchTerm, setSearchTerm] = useState("");

	const [pieModalOpen, setPieModalOpen] = useState(false);
	const [activeBillPage, setActiveBillPage] = useState(1);
	const [votePieID, setVotePieID] = useState("");

	const pageSize =  15;

		//https://stackoverflow.com/questions/71179586/can-i-make-multiple-usemutation-in-a-components
	//const [createUser, {data: createUserData}] = useMutation(CREATE_USER, {user}) // create user
	//const [updateUser, {data: updateUserData}] = useMutation(UPDATE_USER, {user}) // update user

	const [billData, setBillData] = useState([]);
	const [results, setResults] = useState([]);
	const [error, setError] = useState(null); 
	const [isLoading, setIsLoading] = useState(false);

	//const { data, isLoading, error } = () => getApiData(`/api/search/?query=${searchParam}&page=${activePage}`, global.token);
	const { items, requestSort, sortConfig } = useSortableData( []);
	// {peopleData?.people ? peopleData?.people : []}

	const pieBtnClickHandler = (voteEndpoint) => {
		setPieModalOpen(true);
		setVotePieID(voteEndpoint?.replace("ocd-vote/", ""));
	};

	const seeDetailsHandler = (endpoint) => {
		navigate(`/bill-details/${endpoint?.replace("/api", "")?.replaceAll("/", "%2F")}`);
	};

	const getClassNamesFor = (name) => {
		if (!sortConfig) {
		  return;
		}
		return sortConfig.key === name ? sortConfig.direction : undefined;
	  };

	useEffect(() => {
		//mutate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
		//getApiData(`/api/search/bills/?query=${searchParam}&page=${activeBillPage}`, global.token).then(res => setBillData(res) );
		//getApiData(`/api/search/people/?query=${searchParam}&page=${1}`, global.token).then(res => setPeopleData(res) );

	}, []);

	const VoteBtNs = (vote_data) => {
		vote_data = vote_data.vote_data;
		//console.log(vote_data.length);
		if (vote_data?.length > 0){
			return(
				<>
											<VoteBtn
												content={<AiFillPieChart />}
												backgroundColor="rgba(0, 82, 204, 0.29)"
												onClick={() => pieBtnClickHandler(vote_data?.[0])}
											/>
				</>
			)
		}
		return(
			<>No Votes</>
		)
	}

	const changePage = (event) =>{
		//console.log(event);
		setActiveBillPage(event);
		getApiData(`/api/search/bills/?query=${searchParam}&page=${event}`, global.token).then(res => setBillData(res) );
		//mutate();
	};

	const submitHandler = (e) => {
		e.preventDefault();
		//if (pathname !== "/results") navigate("/results", {search: searchTerm});
		//if (pathname !== "/results") {
		//	navigate({
		//		pathname: '/results',
		//		search: `?search=${searchTerm}&order=newest`,
		//	  })
		//}
			// navigate({
			// 	pathname: '/results',
			// 	search: `?search=${searchTerm}&order=newest`,
			//   });
		//mutate();
		console.log(searchTerm);
		getApiData(`/api/search/address/?address=${searchTerm}`, global.token).then(res => {
			setResults(res);
			setError(null);
		}
			).catch(error => {
				setError(error.message);
				setResults([]);
			});
		;
		console.log(results);

	};

	const renderResults = () => {
        console.log(error);
		console.log(results?.people);
        if(results?.people?.length > 0){
            return(
				<TableLegislators query={searchParam} data={results?.people ? results?.people : []} infoTab={infoTab} setInfoTab={setInfoTab} />
            )
        }
		if(error){
            return(
				<Box
					sx={(theme) => ({
					backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
					textAlign: 'center',
					padding: theme.spacing.xl,
					borderRadius: theme.radius.md,
					cursor: 'pointer',
			
					'&:hover': {
						backgroundColor:
						theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
					},
					})}
				>
					There was an error with your request
				</Box>
            )
        }
    }

	//const dataToUse = noPagination ? data : data?.slice((activePage - 1) * pageSize, activePage * pageSize);

	return isLoading ? (
		<Loading />
	) : billData ? (
		<>

<>
		<Title
			sx={{
				color: "#3E435D",
				fontSize: "24px",
				fontWeight: 500,
				paddingTop: "23px",
				lineHeight: "36px",
			}}
		>
			Enter Your Address To Find Your Legislators:
			<form onSubmit={submitHandler}>
				<Input
					value={searchTerm}
					onChange={({ target }) => setSearchTerm(target.value)}
					icon={  <SearchIcon />}
					placeholder="Search"
					sx={{
						input: {
							height: "auto",
							fontSize: "16px",
							padding: "8px 12px 8px 55px",
							border: "none",
							borderRadius: "10px",
						},

						".mantine-Input-icon": { left: "12px" },

						"@media (max-width: 768px)": {
							input: { padding: "8px 12px 8px 40px" },
							".mantine-Input-icon": { left: "6px" },
							".mantine-Input-icon > svg": { width: "20px", height: "20px" },
						},
					}}
				/>
			</form>
		</Title>
	</>

		{renderResults()}		
		</>
	) : (
		<Box sx={{ ...FlexAllCenter, height: "60vh" }}>
			<Title>Please enter a search term...</Title>
		</Box>
	);
};

export default FindMyLegislators;
