import { useState } from "react";
import { Box, Input, Loader } from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { getApiData } from "../../../utils/fetch-api";
import useSearchStore from "../../../stores/search";
import { SearchIcon } from "../../SvgAssets";

//

const SearchBar = ({ flex = 1 }) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [searchTerm, setSearchTerm] = useState("");
	const { setSearchResult, searchBarLoading, setSearchBarLoading } = useSearchStore();

	const { mutate } = useMutation({
		mutationFn: () => getApiData(`/api/search/?query=${searchTerm}`),
		onSuccess: (data) => setSearchResult(data),
		onMutate: () => setSearchBarLoading(true),
		onSettled: () => setSearchBarLoading(false),
	});

	const submitHandler = (e) => {
		e.preventDefault();
		//if (pathname !== "/results") navigate("/results", {search: searchTerm});
		//if (pathname !== "/results") {
		//	navigate({
		//		pathname: '/results',
		//		search: `?search=${searchTerm}&order=newest`,
		//	  })
		//}
			navigate({
				pathname: '/results',
				search: `?search=${searchTerm}&order=newest`,
			  });
		//mutate();
	};

	return (
		<Box sx={{ flex }}>
			<form onSubmit={submitHandler}>
				<Input
					value={searchTerm}
					onChange={({ target }) => setSearchTerm(target.value)}
					icon={searchBarLoading ? <Loader size="sm" /> : <SearchIcon />}
					placeholder="Search"
					sx={{
						input: {
							height: "auto",
							fontSize: "16px",
							padding: "8px 12px 8px 55px",
							border: "none",
							borderRadius: "10px",
						},

						".mantine-Input-icon": { left: "12px" },

						"@media (max-width: 768px)": {
							input: { padding: "8px 12px 8px 40px" },
							".mantine-Input-icon": { left: "6px" },
							".mantine-Input-icon > svg": { width: "20px", height: "20px" },
						},
					}}
				/>
			</form>
		</Box>
	);
};

export default SearchBar;
