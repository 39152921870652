import { Box, TextInput, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import PropTypes from "prop-types";
import FormBtn from "./form-btn";
import { AuthInputStyles, FlexJusBet } from "../../../styles/sx-mixins";
import { useNavigate } from "react-router-dom";

const ForgotForm = ({ formTypeHandler }) => {
	const navigate = useNavigate();

	const form = useForm({
		initialValues: { email: "" },
		validate: {
			email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
		},
	});

	const handleSubmit = (values) => {
		formTypeHandler(values);
		//console.log(values.email);
		signupHandler(values);
	};

	const signupHandler = async ( formEmail ) => {
		console.log(formEmail);
		const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/reset-password/`, {
			method: "POST",
			referrer: "https://api.voterscore.com",
			headers: new Headers({ "Content-Type": "application/json" }),
			body: JSON.stringify({
				email: formEmail.email
			}),
		})
			.then((res) => {
				if (res?.status === 200) {
					navigate("/auth?type=login");
				} else {
					form.setErrors({
						email: "Error finding your account. Try again...",
					});
				}
				return res.json();
			})
			.catch((err) => err.message);
		console.log(response);
		// Status code 201 meaning user account resource created...
	};

	return (
		<form onSubmit={form.onSubmit(handleSubmit)}>
			<TextInput
				styles={AuthInputStyles}
				mb="24px"
				type="email"
				placeholder="Email Address"
				{...form.getInputProps("email")}
			/>

			<Box sx={{ ...FlexJusBet }}>
				<FormBtn text="Reset Password" spacing={["18px 20px", "14px 20px"]} />

				<Text
					component="div"
					color="#0046CDD9"
					my="5px"
					mr="22px"
					sx={{ lineHeight: "17px", cursor: "pointer" }}
					onClick={() => formTypeHandler("login")}
				>
					Sign In
				</Text>
			</Box>
		</form>
	);
};

ForgotForm.propTypes = {
	formTypeHandler: PropTypes.func.isRequired,
};

export default ForgotForm;
