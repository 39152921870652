const CustomTheme = {
	colors: {
		primary: ["#e8f1ff", "#327FEB", ...Array(9).fill("#0052CC", 0, 8)],
		red: ["#BD0202"],
	},
	primaryColor: "primary",
	fontFamily: "inherit",
	components: {
		Title: { styles: { root: { fontFamily: "inherit" } } },
		Button: {
			styles: {
				root: {
					height: "max-content",
					lineHeight: "inherit",
					transitionDuration: "0.2s",
				},
			},
		},
	},
};

export default CustomTheme;
