import { useForm } from "@mantine/form";
import { useSignIn, useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { TextInput, Text, PasswordInput } from "@mantine/core";
import { AuthInputStyles } from "../../../styles/sx-mixins";
import { postApi } from "../../../utils/fetch-api";
import FormBtn from "./form-btn";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

//

const LoginSignupForm = ({ formType, formTypeHandler }) => {
	const navigate = useNavigate();
	const signIn = useSignIn();
	const [captchaResult, setCaptchaResult] = useState()
	const session = useAuthUser();

	const form = useForm({
		initialValues: { email: "", password: "", password2: "" },
		validate: {
			email: (val) => (val ? (/^\S+@\S+$/.test(val) ? null : "Invalid email") : null),
			password: (val) => val.length < 8 && "Minimum 8 characters"
		},
	});

	// useEffect(() => {
	// 	const typeToSwitchTo = () => {
	// 		if (formType.login) return "login";
	// 		if (formType.sign_up) return "sign_up";
	// 	};

	// 	// updating url query string without page refresh...
	// 	navigate({ pathname: "/auth", query: { type: typeToSwitchTo() } }, undefined, { shallow: true });
	// }, [formType.login]);

	const mutationEffects = {
		onSuccess: (data) => {
			const isLoggedIn = signIn({
				token: data?.token,
				expiresIn: 1500,
				tokenType: "Bearer",
				authState: data,
			});
			if (isLoggedIn) {
				//console.log(session());
				//console.log(isLoggedIn);
				navigate("/bills");
			} else {
				throw new Error("Got into some issues. Try again..");
			}
		},
		onError: () => {
			form.setErrors({ email: "Credentials don't match..", password: "Credentials don't match.." });
		},
	};

	const handleRecaptcha = (value) => {
		fetch(`${process.env.REACT_APP_API_URL}/api/auth/recaptcha/`, {
		  method: 'POST',
		  body: JSON.stringify({ 'captcha_value': value }),
		  headers: { 'Content-Type': 'application/json' }
		})
		 .then(res => res.json())
		 .then(data => {
		   console.log(data.captcha.success)
		   setCaptchaResult(data.captcha.success)
		 }) 
	  }

	const { mutate: loginMutate, isLoading: isLoginHandler } = useMutation({
		mutationKey: ["login"],
		mutationFn: ({ email, password }) => postApi("/api/auth/login/", { username:email, password }, null), // we have username:email because login uses username and register uses email.
		...mutationEffects,
	});

	const { mutate: signupMutate, isLoading: isSignupHandler } = useMutation({
		mutationKey: ["signup"],
		mutationFn: (data) => postApi("/api/auth/register/", { ...data, password2: data?.password2 }, null),
		...mutationEffects,
	});

	const signupHandler = async ({ email, password, password2 }) => {
		const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/register/`, {
			method: "POST",
			referrer: "https://api.voterscore.com",
			headers: new Headers({ "Content-Type": "application/json" }),
			body: JSON.stringify({
				email: email,
				password: password,
				password2: password2,
			}),
		})
			.then((res) => {
				if (res?.status === 201) {
				} else {
					form.setErrors({
						email: "Error creating your account. Try again...",
						password: "Error creating your account. Try again...",
					});
				}

				return res.json();
			})
			.catch((err) => err.message);

		console.log(response);

		// Status code 201 meaning user account resource created...
	};

	const formErrorsHandler = (validationErrors, _values, _event) => {
		console.log(validationErrors);
	};

	const submitButtons = (isLogin) => {
		if(isLogin){
			return(
				<FormBtn
					text={formType.login ? "Login" : "Sign Up"}
					loading={isLoginHandler || isSignupHandler}
					spacing={["18px 65px", "14px 50px"]}
				/>
			)
		}
		return(
			<>
				<FormBtn
					text={formType.login ? "Login" : "Sign Up"}
					disabled={!captchaResult}
					loading={isLoginHandler || isSignupHandler}
					spacing={["18px 65px", "14px 50px"]}
				/>
			</>
		)

	};

	return (
		<form onSubmit={form.onSubmit(formType.login ? loginMutate : signupMutate, formErrorsHandler)}>
			<TextInput  autoCapitalize='none' styles={AuthInputStyles} mb="24px" placeholder="Email" {...form.getInputProps("email")} />

			{/* 
			<TextInput styles={AuthInputStyles} mb="24px" placeholder="User Name" {...form.getInputProps("username")} />
			
			{formType.login || (
				<TextInput
					styles={AuthInputStyles}
					mb="24px"
					type="email"
					placeholder="Email Address"
					{...form.getInputProps("email")}
				/>
			)} */}

			<PasswordInput
				styles={AuthInputStyles}
				placeholder="Password"
				visibilityToggleIcon={({ reveal }) => (reveal ? "Hide" : "Show")}
				{...form.getInputProps("password")}
			/>

			{formType.login || (
				<>
					<PasswordInput
						styles={AuthInputStyles}
						placeholder="Confirm Password"
						visibilityToggleIcon={({ reveal }) => (reveal ? "Hide" : "Show")}
						{...form.getInputProps("password2")}
					/>
					<ReCAPTCHA
						sitekey='6LdCU78aAAAAANFXg7u-7ewK6_UKtSRJ2s53FK6b'
						onChange={handleRecaptcha}
					/>
				</>

			)}
			

			<Text
				component="div"
				color="#0046CDD9"
				align="right"
				my="5px"
				sx={{ lineHeight: "17px", cursor: "pointer" }}
				onClick={() => formTypeHandler("forgot")}
			>
				Forgot Password
			</Text>

			{submitButtons(formType.login)}
		</form>
	);
};

export default LoginSignupForm;
