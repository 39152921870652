import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Text, UnstyledButton } from "@mantine/core";
import { ForgotForm, ResetPassword, LoginSignupForm } from "./sub-components";


const AuthForm = () => {
	const [formType, setFormType] = useState({ login: true, sign_up: false, forgot: false, new_pass: false });
	//const { authType } = useParams();
	const queryParameters = new URLSearchParams(window.location.search)
	//console.log(queryParameters.get("type"));
	const authType = queryParameters.get("type");

	useEffect(() => {
		//console.log(authType);
		//formTypeHandler(authType === "sign_up" ? "sign_up" : "login");
		if (authType === null) {
			formTypeHandler("login");
		} 
		else {
			formTypeHandler(authType);
		}
	}, [authType]);

	const formTypeHandler = (type) => {
		//console.log(type);
		/**  Toggling only one form state inside object.  **/
		setFormType((prev) => {
			const newState = { ...prev };
			Object.keys(newState).forEach((key) => {
				newState[key] = key === type;
			});
			return newState;
		});
	};

	const FormSwitch = () => (
		<Box
			sx={{
				display: "flex",
				width: "fit-content",
				gap: "40px",
				borderBottom: "1px solid #DEDEDE",
				marginBottom: "25px",
			}}
		>
			{[
				{ label: "Login", value: "login" },
				{ label: "Sign Up", value: "sign_up" },
			].map(({ label, value }, i) => (
				<UnstyledButton
					key={i}
					sx={{
						color: formType[value] ? "#5269D6" : "#82A5FF",
						borderBottom: formType[value] ? "2px solid #5269D6" : "none",
						fontWeight: 500,
						padding: "16.5px 0",
						lineHeight: "17px",
						cursor: "pointer",
					}}
					onClick={() => formTypeHandler(value)}
				>
					{label}
				</UnstyledButton>
			))}
		</Box>
	);

	const ThirdPartyAuth = () => (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				gap: "14px",
				marginTop: "100px",
				img: { cursor: "pointer" },
				"@media (max-width: 720px)": {
					marginTop: "75px",
				},
			}}
		>
			{/* <Text sx={{ fontSize: "14px", opacity: 0.8 }}>Or login with</Text>
			<img
				loading="lazy"
				src="/assets/facebook-logo.svg"
				alt="Login with Facebook"
				width="47px"
				height="47px"
				onClick={() => alert("Logic for third party Auth")}
			/>
			<img
				loading="lazy"
				src="/assets/google-logo.svg"
				alt="Login with Facebook"
				width="47px"
				height="47px"
				onClick={() => alert("Logic for third party Auth")}
			/> */}
		</Box>
	);

	return (
		<Box>
			{formType["login"] || formType["sign_up"] ? (
				<>
					<FormSwitch />
					<LoginSignupForm formType={formType} formTypeHandler={formTypeHandler} />
				</>
			) : null}

			{formType["login"] || formType["sign_up"] ? <ThirdPartyAuth /> : null}

			{formType["forgot"] && <ForgotForm formTypeHandler={formTypeHandler} />}

			{formType["new_pass"] && <ResetPassword formTypeHandler={formTypeHandler} />}
		</Box>
	);
};

export default AuthForm;
