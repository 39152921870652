import { Link, useLocation, useNavigate } from "react-router-dom";
import { Box, Button, NavLink, Text, Group, ThemeIcon, Anchor, UnstyledButton } from "@mantine/core";
import { useAuthUser, useSignOut } from "react-auth-kit";
import { FlexJusBet } from "../../../styles/sx-mixins";
import { HiMenuAlt1 } from "react-icons/hi";
import { SearchBar } from ".";
import { MdLogin, MdLogout, MdSettings } from "react-icons/md";
import PropTypes from "prop-types";
import { BrandLogo, ProfileIcon, ResultsIcon } from "../../SvgAssets";

//

const TopHeader = ({ isSideNavVisible, setDrawerOpened }) => {
	const session = useAuthUser();
	const signOut = useSignOut();
	//console.log(session());

	// if(session) {
	// 	return <>
	// 	  Signed in as {session.user.email} <br/>
	// 	  <button onClick={() => signOut()}>Sign out</button>
	// 	</>
	//   }
	const navigate = useNavigate();
	const navigateToEndpoint = (endpoint) => {
		console.log(endpoint);
		navigate(endpoint);
	};

	return (
		<Box
			component="header"
			sx={{
				paddingRight: "40px",
				marginTop: "5px",
				marginBottom: "20px",

				"@media (max-width: 1315px)": { marginTop: "18px", padding: "0 15px" },
				"@media (max-width: 434px)": { padding: "0 10px" },
			}}
		>
			<Box sx={{ ...FlexJusBet, gap: "20px", "@media (max-width: 468px)": { gap: "12px" } }}>
				{/* hamburger menu for below 1315px screens */}
				{/* {isSideNavVisible || (
					<Button sx={{ padding: "6px", svg: { width: "23px", height: "23px" } }} onClick={() => setDrawerOpened(true)}>
						<HiMenuAlt1 />
					</Button>
				)} */}

				<Box className="left-col">
				<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					paddingTop: "42px",
					paddingBottom: "52px",
					cursor: "pointer",
				}}
			>
				<Anchor component={Link} to="/home" sx={{ minWidth: "165px", width: "205px", height: "36px" }}>
					<BrandLogo />
				</Anchor>
			</Box>
				</Box>

				<Box className="right-col" sx={{ ...RightNavFlexStyles, flex: 1 }}>
					<Box sx={{ ...RightNavFlexStyles, flex: 1, "@media (max-width: 630px)": { display: "none" } }}>
						<SearchBar flex={0.8} />
					</Box>
					<Box>
					{!session()?.token? (
						<Button
						component={Link}
						to="/auth"
						sx={{ fontSize: "16px", height: "50px", padding: "10px", borderRadius: "10px" }}
						>
						Login
						</Button>
					) : (


<Button
component={Link}
to="/settings"
sx={{ fontSize: "16px", height: "50px", padding: "10px", borderRadius: "10px" }}
>
Profile
</Button>


					)}
					</Box>
				</Box>
			</Box>

			<Box
				className="mobile-search-bar"
				sx={{ ...RightNavFlexStyles, marginTop: "12px", "@media (min-width: 630px)": { display: "none" } }}
			>
				<SearchBar />
			</Box>
		</Box>
	);
};

const NavigationLink = ({ title, icon, url, onClick }) => {
	const { pathname } = useLocation();
	const isActiveLink = pathname.includes(url);

	return (
		<UnstyledButton
			{...(url && { component: Link, to: url })}
			onClick={onClick}
			sx={(theme) => {
				const textColor = isActiveLink ? theme.colors.primary[2] : "rgb(19 19 19 / 25%)";

				return {
					display: "block",
					width: "100%",
					color: textColor,
					padding: "8px 0",
					marginBottom: "14px",
					borderRadius: "sm",
					borderLeft: "3px solid",
					borderColor: isActiveLink ? textColor : "#fff",
					transition: "background-color 0.2s ease-in-out",
					svg: { width: "25px", height: "25px", fill: textColor, stroke: textColor },
					"&:hover": { backgroundColor: theme.colors.primary[0] },
				};
			}}
		>
			<Group>
				<ThemeIcon
					sx={{
						marginLeft: "38px",
						backgroundColor: "transparent",
					}}
				>
					{icon}
				</ThemeIcon>
				<Text sx={{ fontSize: "16px", letterSpacing: "0.2px" }}>{title}</Text>
			</Group>
		</UnstyledButton>
	);
};

NavigationLink.propTypes = {
	title: PropTypes.string.isRequired,
	url: PropTypes.string,
	icon: PropTypes.element.isRequired,
};


const RightNavFlexStyles = {
	display: "flex",
	alignItems: "stretch",
	justifyContent: "flex-end",
	gap: "20px",

	"@media (max-width: 668px)": { gap: "12px" },
};

export default TopHeader;
