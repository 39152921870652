import PropTypes from "prop-types";
import { Button } from "@mantine/core";
import { BouncyBtnStyle } from "../../../styles/sx-mixins";

const VoteBtn = ({ content, backgroundColor, onClick }) => (
	<Button
		onClick={onClick}
		sx={{
			width: "50px",
			height: "50px",
			fontSize: "20px",
			fontWeight: 500,
			lineHeight: "30px",
			padding: "8px",
			borderRadius: "10px",
			textTransform: "uppercase",
			"&, &:hover": { backgroundColor },
			"&:hover": BouncyBtnStyle,
			svg: { width: "100%", height: "100%" },

			"@media (max-width: 990px)": {
				width: "44px",
				height: "44px",
				fontSize: "18px",
				padding: "4px",
			},
		}}
	>
		{content}
	</Button>
);

VoteBtn.propTypes = {
	content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
	backgroundColor: PropTypes.string.isRequired,
	onClick: PropTypes.func,
};

export default VoteBtn;
