const CloseIcon = () => (
	<svg width="100%" height="100%" viewBox="0 0 60 60" fill="white" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M30 0C13.4318 0 0 13.4318 0 30C0 46.5682 13.4318 60 30 60C46.5682 60 60 46.5682 60 30C60 13.4318 46.5682 0 30 0ZM40.11 23.7464C40.6068 23.232 40.8817 22.5431 40.8755 21.828C40.8693 21.1129 40.5824 20.4289 40.0768 19.9232C39.5711 19.4176 38.8871 19.1307 38.172 19.1245C37.4569 19.1183 36.768 19.3932 36.2536 19.89L30 26.1436L23.7464 19.89C23.4948 19.6295 23.1938 19.4217 22.8611 19.2788C22.5284 19.1359 22.1705 19.0606 21.8084 19.0575C21.4462 19.0544 21.0871 19.1234 20.7519 19.2605C20.4168 19.3976 20.1123 19.6001 19.8562 19.8562C19.6001 20.1123 19.3976 20.4168 19.2605 20.7519C19.1234 21.0871 19.0544 21.4462 19.0575 21.8084C19.0606 22.1705 19.1359 22.5284 19.2788 22.8611C19.4217 23.1938 19.6295 23.4948 19.89 23.7464L26.1436 30L19.89 36.2536C19.6295 36.5052 19.4217 36.8062 19.2788 37.1389C19.1359 37.4716 19.0606 37.8295 19.0575 38.1916C19.0544 38.5538 19.1234 38.9129 19.2605 39.2481C19.3976 39.5832 19.6001 39.8877 19.8562 40.1438C20.1123 40.3999 20.4168 40.6024 20.7519 40.7395C21.0871 40.8766 21.4462 40.9456 21.8084 40.9425C22.1705 40.9394 22.5284 40.8641 22.8611 40.7212C23.1938 40.5783 23.4948 40.3705 23.7464 40.11L30 33.8564L36.2536 40.11C36.768 40.6068 37.4569 40.8817 38.172 40.8755C38.8871 40.8693 39.5711 40.5824 40.0768 40.0768C40.5824 39.5711 40.8693 38.8871 40.8755 38.172C40.8817 37.4569 40.6068 36.768 40.11 36.2536L33.8564 30L40.11 23.7464Z"
		/>
	</svg>
);

export default CloseIcon;
