import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider } from "react-auth-kit";
import { MantineProvider } from "@mantine/core";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import MyGlobalStyles from "./styles/global-styles";
import CustomTheme from "./styles/custom-theme";
import AppRoutes from "./AppRoutes";

//

const queryClient = new QueryClient({
	defaultOptions: {
		queries: { staleTime: Infinity, refetchOnWindowFocus: false },
	},
});

//window.state = "US";
//window.filters = { status: "passed_law", committee: "Any", state: "US" };

// might need to uncomment when adding a filter
//const filters = { status: "Trending", committee: "Any", state: "US", party: "", chamber: "chamber" };
//localStorage.setItem('filters', JSON.stringify(filters));
//if (localStorage.getItem("filters") === null || JSON.parse(localStorage.getItem("userState")).length <= 0) {}
	//const filters = { status: "Trending", committee: "Any", state: "US", party: "", chamber: "chamber" };
	//localStorage.setItem('filters', JSON.stringify(filters));
	
if (localStorage.getItem("state") === null) {
	localStorage.setItem('state',"US");
}

if (localStorage.getItem("party") === null) {
	localStorage.setItem('party',"All");
}

if (localStorage.getItem("role") === null) {
	localStorage.setItem('role',"All");
}

if (localStorage.getItem("status") === null) {
	localStorage.setItem('status',"Trending");
}

if (localStorage.getItem("committee") === null) {
	localStorage.setItem('committee',"All");
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<StrictMode>
		<AuthProvider
			authType={"localstorage"}
			authName={"_auth"}
			cookieDomain={window.location.hostname}
			cookieSecure={window.location.protocol === "https:"}
		>
			<QueryClientProvider client={queryClient}>
				<BrowserRouter>
					<MantineProvider withGlobalStyles withCSSVariables withNormalizeCSS theme={CustomTheme}>
						<MyGlobalStyles />
						<AppRoutes />
					</MantineProvider>
				</BrowserRouter>
			</QueryClientProvider>
		</AuthProvider>
	</StrictMode>
);
