import { Box, Title } from "@mantine/core";
import { useAuthUser } from "react-auth-kit";
import { useMediaQuery } from "@mantine/hooks";
import { useQuery } from "@tanstack/react-query";
import { ErrorHandlerLayout, SponsorCards } from "../components";
import { getApiData } from "../utils/fetch-api";

//

const FollowedLegislators = () => {
	const session = useAuthUser();

	const { data, isLoading, error } = useQuery({
		queryKey: ["followed-legislators"],
		queryFn: () => getApiData("/api/accounts/profile/", session()?.token),
		enabled: !!session()?.token,
	});

	const below468px = useMediaQuery("(max-width: 468px)");

	if (!session()?.token){
		return(
			<ErrorHandlerLayout isLoading={false} error={"error"}></ErrorHandlerLayout>
		)
	}

	return (
		<ErrorHandlerLayout isLoading={isLoading} error={error}>
			<Box px={below468px ? "10px" : 0}>
				<Title
					sx={{
						color: "#3E435D",
						fontSize: "28px",
						fontWeight: 900,
						lineHeight: "42px",
						padding: "0 15px",
						marginBottom: "16px",

						"@media (max-width: 434px)": { padding: "0 10px" },
					}}
				>
					Your Most Similar Legislators
				</Title>

				<SponsorCards
					data={data?.followed_legislators}
					responsiveMaxWidth="calc(100vw - 30px)"
					isFollowerTable
					noPagination
				/>
			</Box>
		</ErrorHandlerLayout>
	);
};

export default FollowedLegislators;
