import { Box, createStyles, Title } from "@mantine/core";
import { MdOutlineError } from "react-icons/md";
import { FlexAllCenter } from "../../styles/sx-mixins";

const bounce = createStyles({
	errorIcon: {
		animation: "bounce 1s infinite",
		"@keyframes bounce": {
			"0%, 100%": {
				transform: "translateY(-25%)",
				animationTimingFunction: "cubicBezier(0.8, 0, 1, 1)",
			},
			"50%": {
				transform: "translateY(0)",
				animationTimingFunction: "cubicBezier(0, 0, 0.2, 1)",
			},
		},
	},
});

const NoDataToDisplay = () => {
	const { classes } = bounce();

	return (
		<Box sx={{ ...FlexAllCenter, flexDirection: "column", gap: "28px", height: "40vh", svg: { fontSize: "50px" } }}>
			<MdOutlineError className={classes.errorIcon} />
			<Title sx={{ fontWeight: 600 }}>The data isn&apos;t available...</Title>
		</Box>
	);
};

export default NoDataToDisplay;
