const SearchIcon = () => (
	<svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10.5 20.9547C15.7467 20.9547 20 16.4399 20 10.8706C20 5.30131 15.7467 0.786499 10.5 0.786499C5.25329 0.786499 1 5.30131 1 10.8706C1 16.4399 5.25329 20.9547 10.5 20.9547Z"
			stroke="#ADA7A7"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M21 22.0163L19 19.8933"
			stroke="#ADA7A7"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default SearchIcon;
