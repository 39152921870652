import { Box, UnstyledButton, Text, Title } from "@mantine/core";

const SearchTableHeader = ({ query, infoTab, setInfoTab }) => (
	<>
		<Title
			sx={{
				color: "#3E435D",
				fontSize: "24px",
				fontWeight: 500,
				paddingTop: "23px",
				lineHeight: "36px",
			}}
		>
			Your Search Results for:{" "}
			<Text component="span" color="#1128A0" transform="capitalize">
				{query}
			</Text>
		</Title>

		<Box
			className="table-switch-tabs"
			sx={{
				display: "flex",
				gap: "8px",
				borderBottom: "2px solid rgb(0 0 0 / 0.3)",

				"@media (max-width: 668px)": { gap: "24px" },
				"@media (max-width: 478px)": { justifyContent: "center", gap: 0 },
			}}
		>
			{["Bills", "Legislators"].map((text, i) => (
				<UnstyledButton
					key={i}
					onClick={() => setInfoTab(i)}
					sx={{
						padding: "16px 38px",
						color: infoTab === i ? "#4580F0" : "rgb(0 0 0 / 0.5)",
						transform: "translateY(2px)",
						borderBottom: "2px solid transparent",
						borderColor: infoTab === i ? "#4580F0" : "",

						"@media (max-width: 420px)": { padding: "14px 18px" },
					}}
				>
					{text}
				</UnstyledButton>
			))}
		</Box>
	</>
);

export default SearchTableHeader;
