import { useState, useEffect } from "react";
import { Modal, Text, Title, Box, ActionIcon, UnstyledButton } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import PropTypes from "prop-types";
import Loading from "../../shared/loading";
import { getApiData } from "../../../utils/fetch-api";
import { CloseIcon } from "../../SvgAssets";
import { PieChart } from "react-minimal-pie-chart";
import { FlexAllCenter } from "../../../styles/sx-mixins";

//

const initialPieData = {
	party_votes: {
		Democratic: { yes: 0, no:1, other: 0, absent: 0 },
		Republican: { yes: 0, other: 1 },
		Unknown: { other: 0 },
	},
	vote_bill: { identifier: "" },
};

//

const PieModal = ({ voteId, ...props }) => {
	const [pieData, setPieData] = useState(initialPieData);
	const [pieLoading, setPieLoading] = useState(false);
	const firstPartyInList = Object.keys(pieData?.party_votes)?.[0];
	//console.log(voteId);
	const [voteFromParty, setVoteFromParty] = useState({
		partyName: firstPartyInList,
		...pieData?.party_votes[firstPartyInList],
	});

	const below520px = useMediaQuery("(max-width: 520px)");
	const below760px = useMediaQuery("(max-width: 760px)");
	const below900px = useMediaQuery("(max-width: 900px)");
	const below1280px = useMediaQuery("(max-width: 1280px)");
	const below1380px = useMediaQuery("(max-width: 1380px)");

	useEffect(() => {
		if (voteId && props.opened) {
			(async () => {
				setPieLoading(true);
				await getApiData(voteId, null).then((data) => {
					console.log(data);
					setPieData(data);
					setPieLoading(false);
					setVoteFromParty({ partyName: "All", ...data?.party_votes["All"] })
				});
			})();
		}
	}, [props.opened, voteId]);

	//console.log({ pieData, pieLoading });
	//console.log(voteFromParty);

	const pieChartData = Object.entries(voteFromParty)
		?.map(([title, value]) => {
			//console.log(voteFromParty);
			const bgColor = () => {
				if (title.toLowerCase() === "yea") return "#327FEB";
				else if (title.toLowerCase() === "nay") return "#BD0202";
				else if (title.toLowerCase() === "other") return "gray";
				else if (title.toLowerCase() === "absent") return "black";
			};

			return (
				title !== "partyName" && {
					title: `${title.toUpperCase()}: ${value}%`,
					value,
					color: bgColor(),
				}
			);
		})
		?.filter(Boolean);

	const chartFontSize = (value) => {
		if (value <= 2) return `12%`;
		if (value <= 3) return `20%`;
		else if (value <= 10) return "36%";
		else if (value <= 15) return "50%";
		else if (value <= 25) return "65%";
		else if (value <= 70) return "75%";
		else return `${Math.round(value)}%`;
	};

	const SideLabel = ({ type }) => (
		<Box className="side-label" sx={{ display: "flex", flexDirection: "column", alignItems: "center", whiteSpace: "nowrap" }}>
			<Text
				sx={({ colors }) => ({
					color: type === "yea" ? colors.primary[1] : colors.red[0],
					fontSize: below520px ? "50px" : below760px ? "65px" : below1380px ? "80px" : "100px",
					fontWeight: 900,
					lineHeight: below760px ? "65px" : "90px",
					textTransform: "uppercase",
				})}
			>
				{type}
			</Text>
			<Text sx={{ fontSize: below520px ? "30px" : below1380px ? "34px" : "40px", fontWeight: 500, opacity: 0.25 }}>
				{voteFromParty[type]} Users
			</Text>
		</Box>
	);

	return (
		<Modal
			{...props}
			size={below760px ? "100%" : "88%"}
			withCloseButton={false}
			centered
			styles={({ colors }) => ({
				root: { fontFamily: "Poppins, sans-serif" },
				modal: { padding: "0 !important", borderRadius: "20px", overflow: "hidden" },
				header: { display: "block", marginRight: 0, backgroundColor: colors.primary[1] },
			})}
		>
			{voteId ? (
				pieLoading ? (
					<Loading />
				) : (
					<>
						<Box
							className="header"
							sx={({ colors }) => ({
								position: "relative",
								color: "white",
								backgroundColor: colors.primary[1],
								borderBottom: "1px solid black",
								textAlign: "center",
								padding: "6px",
								marginBottom: "8px",
							})}
						>
							<Title sx={{ fontSize: below520px ? "42px" : below900px ? "50px" : "70px", fontWeight: 900 }}>
								User Results
							</Title>
							<Text
								sx={{
									fontSize: below520px ? "26px" : below900px ? "30px" : "50px",
									textTransform: "uppercase",
								}}
							>
								Bill {pieData.vote_bill.identifier}
							</Text>

							<ActionIcon
								onClick={props.onClose}
								variant="transparent"
								size="46px"
								sx={{
									display: below520px ? "none" : "initial",
									position: "absolute",
									top: "15%",
									left: "1.8%",
									transitionDuration: "200ms",
									"&:hover": { transform: "scale(1.05)" },
								}}
							>
								<CloseIcon />
							</ActionIcon>
						</Box>

						<Box
							className="party-selection"
							sx={{
								...FlexAllCenter,
								flexWrap: "wrap",
								columnGap: "25px",
								rowGap: "10px",
								padding: "0 15px 11px",
								borderBottom: "1px solid black",
								marginBottom: below760px ? "25px" : "45px",
							}}
						>
							<Text sx={{ fontSize: below900px ? "28px" : "40px", fontWeight: 500, opacity: 0.5 }}>Votes From:</Text>


							{Object.keys(pieData?.party_votes)?.map((party, i) => (
								<UnstyledButton
									key={i}
									onClick={() => setVoteFromParty({ partyName: party, ...pieData?.party_votes[party] })}
									sx={({ colors }) => {
										const selectedParty = voteFromParty?.partyName;

										return {
											height: below900px ? "55px" : "80px",
											fontSize: below900px ? "28px" : "40px",
											fontWeight: 900,
											color: selectedParty === party ? "white" : colors.primary[1],
											backgroundColor: selectedParty === party ? colors.primary[1] : "white",
											padding: "8px 18px",
											borderRadius: "10px",
											border: "5px solid",
											borderColor: colors.primary[1],
											boxShadow: "2px 7px 5px rgb(0 0 0 / 0.2)",
											whiteSpace: "nowrap",
										};
									}}
								>
									{party}
								</UnstyledButton>
							))}
						</Box>

						<Box
							className="chart-section"
							sx={{
								display: "flex",
								flexDirection: below1280px ? "column-reverse" : "row",
								gap: "40px",
								padding: "0 24px",
								marginBottom: "42px",
							}}
						>
							<Box sx={{ ...FlexAllCenter, gap: "10%", display: below1280px ? "flex" : "none" }}>
								<SideLabel type="yea" />
								<SideLabel type="nay" />
							</Box>

							<Box
								className="chart-section-inner"
								sx={{
									...FlexAllCenter,
									flex: 1,
									flexDirection: below1280px ? "column" : "row",
									gap: below1280px ? "30px" : "52px",

									".side-label": { display: below1280px ? "none" : "flex" },
								}}
							>
								<SideLabel type="yea" />
									<Box className="pie-inner-wrapper" sx={{ borderRadius: "50%", overflow: "hidden" }}>
										<PieChart
											data={pieChartData}
											radius={50}
											startAngle={90}
											//segmentsShift={0.5}
											label={({ x, y, dx, dy, dataEntry }) => (
												// custom <text> element from piechart library..
												<text
													key={dx}
													x={x}
													y={y}
													dx={dx}
													dy={dy}
													dominantBaseline="central"
													textAnchor="middle"
													style={{
														fill: "white",
														fontFamily: "Poppins, sans-serif",
														fontWeight: 900,
														fontSize: chartFontSize(dataEntry.percentage),
													}}
												>
													{Math.round(dataEntry.percentage)}%
												</text>
											)}
											animate
										/>
									</Box>
								<SideLabel type="nay" />
							</Box>
						</Box>
					</>
				)
			) : (
				<Title
					sx={{
						fontSize: below900px ? "32px" : "38px",
						margin: "80px 20px",
						textAlign: "center",
					}}
				>
					No Voting data found for this Bill
				</Title>
			)}
		</Modal>
	);
};

PieModal.propTypes = {
	opened: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	// might be missing for lack of data in the backend..
	voteId: PropTypes.string,
};

export default PieModal;
