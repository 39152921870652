import { useState,useMemo } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Group, Box, Table, Pagination, Text, Avatar } from "@mantine/core";
import { FaStar } from "react-icons/fa";
import NoDataToDisplay from "../shared/no-data";
import TableBtn from "./sub-components/table-button";
import SearchTableHeader from "./sub-components/search-table-header";
import { FlexAllCenter } from "../../styles/sx-mixins";
import { FaChevronRight } from "react-icons/fa";


const useSortableData = (items, config = null) => {
	const [sortConfig, setSortConfig] = useState(config);
  
	const sortedItems = useMemo(() => {
	  let sortableItems = [...items];
	  if (sortConfig !== null) {
		sortableItems.sort((a, b) => {
		  if (a[sortConfig.key] < b[sortConfig.key]) {
			return sortConfig.direction === 'ascending' ? -1 : 1;
		  }
		  if (a[sortConfig.key] > b[sortConfig.key]) {
			return sortConfig.direction === 'ascending' ? 1 : -1;
		  }
		  return 0;
		});
	  }
	  return sortableItems;
	}, [items, sortConfig]);
  
	const requestSort = (key) => {
	  let direction = 'ascending';
	  if (
		sortConfig &&
		sortConfig.key === key &&
		sortConfig.direction === 'ascending'
	  ) {
		direction = 'descending';
	  }
	  setSortConfig({ key, direction });
	};
  
	return { items: sortedItems, requestSort, sortConfig };
  };

const TableLegislators = ({
	data,
	query,
	isSponsorTable,
	isFollowerTable,
	noHeader,
	smallSpacing,
	infoTab,
	setInfoTab,
	responsiveMaxWidth,
	tablePageSize,
	noPagination,
}) => {
	const [activePage, setActivePage] = useState(1);
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const pageSize = tablePageSize || 8;
	const { items, requestSort, sortConfig } = useSortableData(data ? data : []);

	const dataToUse = noPagination ? items : items?.slice((activePage - 1) * pageSize, activePage * pageSize);

	//if (data?.length === 0) return <NoDataToDisplay />;

	const seeDetailsHandler = (endpoint) => {
		//console.log(endpoint);
		//navigate(`/legislator-details/${endpoint?.replace("/api/person/", "")?.replaceAll("/", "%2F")}`);
		navigate(`/legislator-details/${endpoint?.replace("/api/person/", "")}`);

	};
	const getClassNamesFor = (name) => {
		if (!sortConfig) {
		  return;
		}
		return sortConfig.key === name ? sortConfig.direction : undefined;
	  };

	const partyColor = (partyName) => {
		const options = { Democratic: "#0052CC", Republican: "#BD0202", Independent: "#5E5E5E" };
		return options?.[partyName];
	};

	return (
		<Box sx={{ "@media (min-width: 1315px)": { paddingRight: smallSpacing ? 0 : "40px" } }}>
			<Box
				sx={{
					backgroundColor: "white",
					borderRadius: "10px",
					marginBottom: smallSpacing ? 0 : "40px",
					overflowX: "auto",
				}}
			>
				<Box
					sx={{
						maxWidth: responsiveMaxWidth || "calc(100vw - 40px)",
						overflowX: "auto",
						paddingLeft: smallSpacing ? 0 : "33px",
						paddingRight: smallSpacing ? 0 : "36px",
						// fixing scrollbar glitch while paginating...
						overflowY: "hidden",

						"@media (max-width: 1060px)": { padding: smallSpacing ? 0 : "0 15px" },
						"@media (max-width: 434px)": {
							maxWidth: "calc(100vw - 30px)",
							padding: smallSpacing ? 0 : "0 8px",
						},
					}}
				>
					{pathname === "/results" && <SearchTableHeader query={query} infoTab={infoTab} setInfoTab={setInfoTab} />}

					{dataToUse?.map((obj, i) => {
								let legislator = isSponsorTable ? obj.person : obj;
								let legislator_vote_score = 0;
								if (isFollowerTable) {
									legislator = isFollowerTable ? obj.legislator : obj;
									legislator_vote_score = obj?.vote.vote_likeness;
								}
								if (legislator?.primary_party == "Democrat"){
									legislator.primary_party = "Democratic";
								}
								// console.log(obj);
								// console.log(obj.vote);

								return (
									legislator && (

										<div key={i} >
										<Group wrap="nowrap">
										  <Avatar
											src={  legislator?.image_url != null ? legislator?.image_url : "/assets/userImage.png"  }
											size={94}
											radius="md"
										  />
										  <div>							  
											<Text fz="lg" fw={500} onClick={() => seeDetailsHandler(legislator?.pretty_url)}>
												{legislator?.name}
											</Text>

											<Text fz="xs" tt="uppercase" fw={700} c="dimmed">
												<Box
													component="span"
													sx={{
														color: partyColor(legislator?.primary_party),
														fontWeight: 700,
													}}
												>
													{legislator?.primary_party}{" "}
													{legislator?.primary_party !== "Independent" && " Party"}
												</Box>
											</Text>
								  
											<Group wrap="nowrap" gap={10} mt={3}>
											  <Text fz="xs" c="dimmed">
											  	{legislator?.current_role?.state}
												{legislator?.current_role.district}
											  </Text>
											</Group>
											</div>
											<div>
		  
											<Group wrap="nowrap" gap={10} mt={5}>
											  <Text fz="xs" c="dimmed">
												{isFollowerTable && (
													<Box
														sx={{
															...FlexAllCenter,
															gap: "7px",
															color: legislator_vote_score >= 90 ? "#58B90C" : "black",
															fontSize: "22px",
															fontWeight: 900,
															lineHeight: "33px",
															svg: { fontSize: "24px" },
														}}
													>
														{legislator_vote_score}% {legislator_vote_score >= 90 && <FaStar />}
													</Box>
											)}
											  </Text>
											</Group>
										  </div>
										  <div>
										  	<FaChevronRight onClick={() => seeDetailsHandler(legislator?.pretty_url)}/>

										  </div>
										</Group>
									  </div>
									)
								);
							})}

				</Box>

				{noPagination || (
					<Pagination
						total={Math.round(data?.length / pageSize)}
						page={activePage}
						onChange={setActivePage}
						sx={{ justifyContent: "center", margin: "16px auto" }}
						styles={{ item: { fontFamily: "inherit" } }}
						withEdges
					/>
				)}
			</Box>
		</Box>
	);
};

const FollowBtn = ({ isFollowerTableBtn }) => {
	const [isFollowing, setIsFollowing] = useState(false);

	return (
		<TableBtn
			onClick={() => setIsFollowing((prev) => !prev)}
			sxProps={{
				"&, &:hover": {
					backgroundColor: isFollowing ? "rgba(0, 82, 204, 0.69)" : "rgba(0, 82, 204, 0.29)",
				},
				...(isFollowerTableBtn && {
					padding: "3.5px 28px",
					"&, &:hover": { backgroundColor: "rgba(0, 82, 204, 0.69)" },
				}),
			}}
		>
			{isFollowing ? "Following" : "Follow"}
		</TableBtn>
	);
};

TableLegislators.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object),
	query: PropTypes.string,
	responsiveMaxWidth: PropTypes.string,
	isSponsorTable: PropTypes.bool,
	isFollowerTable: PropTypes.bool,
	noHeader: PropTypes.bool,
	smallSpacing: PropTypes.bool,
	noPagination: PropTypes.bool,
	infoTab: PropTypes.number,
	tablePageSize: PropTypes.number,
	setInfoTab: PropTypes.func,
};



const SliderStyles = {
	root: {
		position: "absolute",
		bottom: "11px",
		left: "21px",
		right: "21px",
		width: "auto",
		height: "5px",
		cursor: "default",
	},
	track: {
		height: "5px",
		borderRadius: "20px",
		"::before": { backgroundColor: "white" },
	},
	bar: { backgroundColor: "white" },
	label: {
		top: -57,
		fontSize: "26px",
		fontWeight: 700,
		lineHeight: "39px",
		borderRadius: "6px",
		padding: "6px 9px 14px",
		background: "linear-gradient(180deg, #FF8888 0%, #FFC4C4 100%)",
		clipPath: "polygon( 0% 0%, 100% 0%, 100% 76%, 50% 100%, 0% 76%)",
	},
	thumb: {
		height: 0,
		background: "transparent",
		borderColor: "transparent",
		color: "transparent",
		cursor: "default",
	},
};


export default TableLegislators;
