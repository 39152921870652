import axios from "axios";

export const getApiData = async (endpoint, authToken) => {
	const { data } = await axios.get(`${process.env.REACT_APP_API_URL}${endpoint}`, {
		...(authToken === null ? {} : { headers: { Authorization: `Token ${authToken}` } }),
		// timeout: 3000,
	});

	if (process.env.NODE_ENV === "development") {
		console.log("%c API fetching....", "color: green; font-weight: 800;");
	}

	return data;
};

export const postApi = async (endpoint, formData, authToken) => {
	//return await axios.post(`${process.env.REACT_APP_API_URL}${endpoint}`, formData)?.then((res) => res?.data);
	const { data } = await axios.post(`${process.env.REACT_APP_API_URL}${endpoint}`, formData, {
		...(authToken === null ? {} : { headers: { Authorization: `Token ${authToken}` } }),
		// timeout: 3000,
	});

	if (process.env.NODE_ENV === "development") {
		console.log("%c API fetching....", "color: green; font-weight: 800;");
	}

	return data;

};
